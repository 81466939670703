


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import LargeLogoSVG from '!vue-svg-loader!@/assets/logo_large.svg';
import { login, errorEventBus } from '../service';

@Component({ components: { LargeLogoSVG } })
export default class About extends Vue {
  username: string = '';
  password: string = '';

  loginLoading: boolean = false;
  valid: boolean = false;

  async login() {
    if (!this.valid) {
      return;
    }

    this.loginLoading = true;
    try {
      await login({
        username: this.username,
        password: this.password
      });
      this.$router.push('/app/playlists/browse');
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.loginLoading = false;
    }
  }
}
